/* could this be defined in the mui theme file instead? */
.MuiTypography-button {
  font-weight: 400;
}

button.MuiTab-root.Mui-selected {
  background-color: #fff;
}

button.MuiTab-root.Mui-selected .MuiTab-wrapper {
  color: #25364d;
}

button.MuiTab-textColorInherit:disabled .MuiTab-wrapper {
  /* color: #916434; */
}

.MuiTablePagination-root:last-child {
  padding: 18px 0 9px;
}
